import React from "react";
import $ from "jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./user.css";
import { Link } from "react-router-dom";
import { logout, setSelectedAddress, set_profile, defaultAddress, set_OfficeAddress, setAddress, setLoading } from "../../../store/Actions/authActions";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import Config from "../../Config";
import { fetchCartItem, getProducts, selectAddress } from "../../../store/Actions/cartActions";
import cookies from "js-cookie";
import EditAddress from "../Modals/NewAddress/EditAddress";
import DeliveryDetail from "../DeliveryDetail";
import ChangeAddress from "../DeliveryDetail/changeAddressModal";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
// import EditAddress from "../Modals/EditAddress";
// import EditAddress from "../NewAddress/EditAddress/index";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown: false,
      mobileDropdown: false,
      isOpen: false,
      username: null,
      defaultAddress: null,
      firstName: null,
      lastName: null,
      detailSelectedAddress: [],
      en: null,
      edit: false,
      selected: [],
    };
    // this.dropdownRef = React.createRef()
  }



  handleClick = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };


  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    // this.props?.getProducts();
    if (this.props.history.location.pathname === "/home") {
      this.props.setLoading(true)
      this.fetchData()
    }
    const uid = this.props?.authUser?.user_uid;
    const client = this.props?.authUser?.user_client;
    const accessToken = this.props?.authUser?.user_access_token;

    this.setState({
      uid,
      client,
      accessToken,
    });
    this.profile()
    $("#app-container").removeClass("menu-default");
    $("#app-container").removeClass("show-spinner");
    const office = this.props?.officeAddress.filter(add => add.is_default === true);
    const home = this.props?.deliveryAddress.filter(add => add.is_default === true);

    if (office?.length > 0) {
      this.setState({ ...this.state, defaultAddress: office?.[0]?.address, detailSelectedAddress: office })
    }
    else if (home?.length > 0) {
      this.setState({ ...this.state, defaultAddress: home?.[0]?.address, detailSelectedAddress: home })
    }
    else {
      this.setState({ ...this.state, defaultAddress: this.props?.t("Complete Your Address") })
    }
  }

  profile = () => {
    const profile = this.props?.userProfile?.firstName
    this.props?.set_profile()
  }

  addAddress = () => {
    this.props.history.push("/use-wosh")
  }


  handleLogout = () => {
    this.props.logout(this.props);
    // .then((response) => {
    //   window.location.href = "https://woshfrontweb.herokuapp.com/";
    //   return response;
    // })
    // .catch((err) => {
    //   // window.location.href = "https://woshfrontweb.herokuapp.com/home";
    //   return err;
    // });
  };



  fetchData = async () => {
    await this.props.set_OfficeAddress();
    await this.props.setAddress();
    await this.props?.getProducts();
    await this.props?.fetchCartItem();
    this.setAdd();
    this.props.setLoading(false)
  }

  setDefaultAddress = (address) => {
    this.props.setLoading(true)
    // debugger
    this.props.defaultAddress(address).then(() => {
      this.fetchData()

    })
  }

  componentWillUnmount() {
    this.setAdd();
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setAdd = () => {
    let h = this.props?.hAddress?.filter((item) => {
      return item?.is_default === true;
    });
    let o = this.props?.oAddress?.filter((item) => {
      return item?.is_default === true;
    });

    if (h?.length > 0) {
      this.props.setSelectedAddress(h[0]?.id, "home");
      this.props?.selectAddress("home");
      this.setState({ ...this.state, defaultAddress: h[0]?.address, detailSelectedAddress: h })
    }

    if (h?.length === 0 && o?.length > 0) {
      this.props?.setSelectedAddress(o[0]?.id, "office");
      this.props?.selectAddress("office");
      this.setState({ ...this.state, defaultAddress: o[0]?.address, detailSelectedAddress: o })
    }
  };


  // defaultAddress = async (address) => {
  //   console.log(address)
  //   await Axios({
  //     url: `${Config.apiURL}/customer/default_addresses`,
  //     method: "POST",
  //     data: {
  //       id: address.id,
  //       address_type: address.type
  //     },
  //     headers: {
  //       uid: `${this.state.uid}`,
  //       client: `${this.state.client}`,
  //       "access-token": `${this.state.accessToken}`,
  //     },
  //   }).then((response) => {

  //     this.setState({ defaultAddress: address.address })

  //   })
  //     .catch((error) => {
  //       return (
  //         console.log('error')
  //       )
  //     });
  // }

  handleSubmit = (selected) => {
    if (this.state.detailSelectedAddress?.[0]?.id != selected.id) {
      this.setDefaultAddress(selected)
    }
  }

  blacklist = ["/sign-in", "/forgot-password", "/connect-point", "/use-wosh", "/thank-you", "/delivery-details", "/personal-details", "/new-user-password"]

  // handleClickOutside = (event) => {
  //   if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target)) {
  //     this.setState({ isOpen: false });
  //   }
  // };


  render() {
    const pathname = window.location.pathname;
    const { isOpen, haveText } = this.state;

    return (
      !this.blacklist.includes(this.props.history.location.pathname) &&
      <span>
        <>
          {/* <nav className="navbar navbar-expand-lg navbar-dark paddbzero">
          <div className="container-fluid paddx40px">
            <Link to="/home">
              <div className="d-flex flex-row px-sm-3 px-0 pt-4">
                <img
                  src="../assets/imgs/logoDark.png"
                  width={150}
                  className="login_logo img-fluid"
                  alt=""
                />
              </div>
            </Link>
            {this.props.isNav && (
              <span>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon" />
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li className="nav-item mx-4">
                      <Link
                        className={`nav-link pb-1 ${
                          pathname === "/home" ? "active" : ""
                        }`}
                        aria-current="page"
                        to="/home"
                      >
                        {this.props.t("Home")}
                      </Link>
                    </li>
                    <li className="nav-item mx-4">
                      <Link
                        className={`nav-link pb-1 ${
                          pathname === "/order" ? "active" : ""
                        }`}
                        to="/order"
                      >
                        {this.props.t("orders")}
                      </Link>
                    </li>
                    <li className="nav-item mx-4">
                      <Link
                        className={`nav-link pb-1 ${
                          pathname === "/profile" ? "active" : ""
                        }`}
                        to="/profile"
                      >
                        {this.props.t("profile")}
                      </Link>
                    </li>
                    <li className="nav-item mx-4">
                      <Link
                        onClick={() => {
                          this.handleLogout();
                        }}
                        className={`nav-link pb-1 ${
                          pathname === "/sign-in" ? "active" : ""
                        }`}
                      >
                        {this.props.t('logout')}
                      </Link>
                    </li>
                  </ul>
                </div>
              </span>
            )}
          </div>
        </nav> */}
        </>
        {this.props.isNav ? (
          <nav className="navbar navbar-expand-lg navbar-light bg-white zIndexMain nav-shadow">
            <div className="container-fluid margin_botm">
              <div className="w-auto showInMobile">
                <Link to="/home">
                  <img
                    src="../assets/imgs/logoDark.png"
                    width={150}
                    className="login_logo img-fluid"
                    alt=""
                  />
                </Link>
                <div
                  className={
                    isOpen
                      ? "minwidth400px overflow-hidden end-50 start-50 translate-middle-x dropdown active"
                      : this.props.history.location.pathname === '/checkout' ? "minwidth400px overflow-hidden end-50 start-50 translate-middle-x dropdown theme_bg color_white"
                        : "minwidth400px overflow-hidden end-50 start-50 translate-middle-x dropdown"
                  }
                  // ref={this.dropdownRef}
                  onClick={this.handleClick}
                >
                  <div className={`dropdown__text text-center ${this.props.history.location.pathname === "/checkout" ? "color_white" : "theme_color"} text_hidden`}>
                    <div className="overflow-hidden text_hidden">
                      {this.state.defaultAddress}
                    </div>
                  </div>
                  <div className="dropdown__items scroll_header_dropdown">
                    {
                      this.props?.deliveryAddress.length ? (
                        <>
                          <h5 className="text-decoration-underline mx-3 my-2 theme_color">{this.props.t("Home address")}</h5>
                          <div className="text-center">
                            {this.props?.deliveryAddress
                              && this.props?.deliveryAddress?.map((item, index) => {
                                return (
                                  <>
                                    <div value={item.id}
                                      onClick={() => {
                                        this.setState({ selected: item })
                                      }
                                      }
                                      className='dropdown__item_address d-flex justify-content-between align-items-center'
                                    >
                                      <div className="w-100"
                                        data-bs-toggle="modal"
                                        data-bs-target="#changeAddress"
                                      >
                                        {`${item?.address}`}
                                      </div>
                                      {/* <div className="text-end"
                                        onClick={() => {
                                          this.props.setSelectedAddress(item)
                                        }}> */}
                                      <Link to={{
                                        pathname: '/delivery-details',
                                        state: {
                                          edit: true,
                                          editable: item
                                        }
                                      }} className="pencil">
                                        <span aria-hidden="true" class="fa fa-pencil fa-1x p-1"></span>
                                      </Link>
                                      {/* </div> */}
                                    </div>
                                  </>
                                );
                              })
                            }
                          </div>
                        </>
                      ) : null
                    }
                    {
                      this.props?.officeAddress.length ? (
                        <>
                          <h5 className="text-decoration-underline mx-3 my-2 theme_color">{this.props.t("Office address")}</h5>
                          <div className="text-center">
                            {this.props?.officeAddress
                              && this.props?.officeAddress?.map((item, index) => {
                                return (
                                  <>
                                    <div value={item.id} className='dropdown__item_address d-flex justify-content-between align-items-center'
                                      //  onClick={() => {
                                      //    if (this.state.detailSelectedAddress?.[0]?.id != item.id) {
                                      //      this.setState({selected : item})
                                      //     }
                                      //   }}
                                      onClick={() => {
                                        this.setState({ selected: item })
                                      }
                                      }
                                      data-bs-toggle="modal"
                                      data-bs-target="#changeAddress"
                                    >
                                      <div
                                        className="w-100">
                                        {`${item?.address}`}
                                      </div>
                                    </div>
                                  </>
                                );
                              })
                            }
                          </div>
                        </>
                      ) : null
                    }
                    <div className="m-3">
                      <Link to={'/use-wosh'}>
                        <button className="w-100 btn fill_btn fw-normal" >
                          {this.props.t("Add address")}
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <button
                onClick={() => {
                  this.setState({ mobileDropdown: !this.state.mobileDropdown })
                }}
                className="navbar-toggler showInMobile"
                type="button"
                // data-bs-toggle="collapse"
                // data-bs-target="#navbarSupportedContent"
                // aria-controls="navbarSupportedContent"
                // aria-expanded="false"
                // aria-label="Toggle navigation"
                >
                <span className="navbar-toggler-icon"
                />
              </button>
              <div className="navbar-collapse display-flex justify-content-between position-relative" id="navbarSupportedContent">
                <div className="min_width hiddenInMobile">
                  <Link to="/home">
                    <img
                      src="../assets/imgs/logoDark.png"
                      // width={150}
                      height={62}
                      className="login_logo"
                      alt=""
                    />
                  </Link>
                  <div
                    className={
                      isOpen
                        ? "minwidth400px overflow-hidden end-50 start-50 translate-middle-x dropdown active"
                        : this.props.history.location.pathname === '/checkout' ? "minwidth400px overflow-hidden end-50 start-50 translate-middle-x dropdown theme_bg color_white"
                          : "minwidth400px overflow-hidden end-50 start-50 translate-middle-x dropdown"
                    }
                    // ref={this.dropdownRef}
                    onClick={this.handleClick}
                  >
                    <div className={`dropdown__text text-center ${this.props.history.location.pathname === "/checkout" ? "color_white" : "theme_color"} text_hidden w-100`}>
                      <div className="overflow-hidden text_hidden">
                        {this.state.defaultAddress}
                      </div>
                    </div>
                    <div className="dropdown__items scroll_header_dropdown">
                      {
                        this.props?.deliveryAddress.length ? (
                          <>
                            <h5 className="text-decoration-underline mx-3 my-2 theme_color">{this.props.t("Home address")}</h5>
                            <div className="text-center">
                              {this.props?.deliveryAddress
                                && this.props?.deliveryAddress?.map((item, index) => {
                                  return (
                                    <>
                                      <div value={item.id}
                                        onClick={() => {
                                          this.setState({ selected: item })
                                        }
                                        }
                                        className='dropdown__item_address d-flex justify-content-between align-items-center'
                                      >
                                        <div className="w-100"
                                          data-bs-toggle="modal"
                                          data-bs-target="#changeAddress"
                                        >
                                          {`${item?.address}`}
                                        </div>
                                        {/* <div className="text-end"
                                        onClick={() => {
                                          this.props.setSelectedAddress(item)
                                        }}> */}
                                        <Link to={{
                                          pathname: '/delivery-details',
                                          state: {
                                            edit: true,
                                            editable: item
                                          }
                                        }} className="pencil">
                                          <span aria-hidden="true" class="fa fa-pencil fa-1x p-1"></span>
                                        </Link>
                                        {/* </div> */}
                                      </div>
                                    </>
                                  );
                                })
                              }
                            </div>
                          </>
                        ) : null
                      }
                      {
                        this.props?.officeAddress.length ? (
                          <>
                            <h5 className="text-decoration-underline mx-3 my-2 theme_color">{this.props.t("Office address")}</h5>
                            <div className="text-center">
                              {this.props?.officeAddress
                                && this.props?.officeAddress?.map((item, index) => {
                                  return (
                                    <>
                                      <div value={item.id} className='dropdown__item_address d-flex justify-content-between align-items-center'
                                        //  onClick={() => {
                                        //    if (this.state.detailSelectedAddress?.[0]?.id != item.id) {
                                        //      this.setState({selected : item})
                                        //     }
                                        //   }}
                                        onClick={() => {
                                          this.setState({ selected: item })
                                        }
                                        }
                                        data-bs-toggle="modal"
                                        data-bs-target="#changeAddress"
                                      >
                                        <div
                                          className="w-100">
                                          {`${item?.address}`}
                                        </div>
                                      </div>
                                    </>
                                  );
                                })
                              }
                            </div>
                          </>
                        ) : null
                      }
                      <div className="m-3">
                        <Link to={'/use-wosh'}>
                          <button className="w-100 btn fill_btn fw-normal" >
                            {this.props.t("Add address")}
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.mobileDropdown &&
                  <div className=" navbar-collapse showInMobile min_width" id="navbarSupportedContent">
                    <div className={`w-100 position-absolute headerDropdown bg-white p-1 showInMobile headerDropdown_screen_2
                     ${cookies.get("i18next") === "en" ? "headerDropdown" : cookies.get("i18next") === "fn" ? "headerDropdown_screen_2" : "headerDropdown_screen_2"}`}>
                      <div className="bg-transparent border-0">
                        <div className="menu_spacing theme_color text-center fw-bold mt-3">
                          {this.props?.userProfile?.firstName ? this.props?.userProfile?.firstName?.toUpperCase() : null}
                          {" "}
                          {this.props?.userProfile?.lastName ? this.props?.userProfile?.lastName?.toUpperCase() : null}
                          <div onClick={() => this.setState({ mobileDropdown: false })}>
                            <Link
                              className={`nav-link border-bottom fw-normal theme_color d-flex flex-row justify-content-center align-items-center ${pathname === "/profile" ? "active" : ""
                                }`}
                              to="/profile"
                            >
                              <div className="navIcons">
                                <img
                                  src="../assets/imgs/account.png"
                                  width={20}
                                  className="img-fluid mr-3"
                                  alt=""
                                />
                              </div>
                              {this.props.t("profile")}
                            </Link>
                            <Link
                              className={`nav-link border-bottom fw-normal theme_color d-flex flex-row justify-content-center align-items-center ${pathname === "/order" ? "active" : ""
                                }`}
                              to="/order"
                            >
                              <div className="navIcons">
                                <img
                                  src="../assets/imgs/order.png"
                                  width={20}
                                  className="img-fluid mr-3"
                                  alt=""
                                />
                              </div>
                              {this.props.t("orders")}
                            </Link>
                            <Link
                              className={`nav-link border-bottom fw-normal theme_color d-flex flex-row justify-content-center align-items-center ${pathname === "/profile" ? "active" : ""
                                }`}
                              to="/paymentMethod"
                            >
                              <div className="navIcons">
                                <img
                                  src="../assets/imgs/wallet.png"
                                  width={20}
                                  className="img-fluid mr-3"
                                  alt=""
                                />
                              </div>
                              {this.props.t("Payment & Wallet")}
                            </Link>
                            <Link
                              className={`nav-link border-bottom fw-normal theme_color d-flex flex-row justify-content-center align-items-center ${pathname === "/profile" ? "active" : ""
                                }`}
                              to="/laundry_partner"
                            >
                              <div className="navIcons">
                                <img
                                  src="../assets/imgs/Laundry.png"
                                  width={20}
                                  className="img-fluid mr-3"
                                  alt=""
                                />
                              </div>
                              {this.props.t("Laundry Partner")}
                            </Link>
                            <Link
                              className={`nav-link border-bottom fw-normal theme_color d-flex flex-row justify-content-center align-items-center ${pathname === "/profile" ? "active" : ""
                                }`}
                              to="/earn-wosh-credit"
                            >
                              <div className="navIcons">
                                <img
                                  src="../assets/imgs/woshcredit.png"
                                  width={20}
                                  className="img-fluid mr-3"
                                  alt=""
                                />
                              </div>
                              {this.props.t("Earn Wosh Credits")}
                            </Link>
                            <a
                              className={`nav-link border-bottom fw-normal theme_color d-flex flex-row justify-content-center align-items-center`}
                              href="https://www.wosh.be/contact"
                              target="_blank" rel="noreferrer"
                            >
                              <div className="navIcons">
                                <img
                                  src="../assets/imgs/faq.png"
                                  width={20}
                                  className="img-fluid mr-3"
                                  alt=""
                                />
                              </div>
                              {this.props.t("FAQ & Contact Us")}
                            </a>
                          </div>
                          <Link
                            onClick={() => {
                              this.handleLogout();
                            }}
                            className={`nav-link fw-normal theme_color d-flex flex-row justify-content-center align-items-center ${pathname === "/sign-in" ? "active" : ""
                              }`}
                          >
                            <div className="navIcons">
                              <img
                                src="../assets/imgs/logout.png"
                                width={20}
                                className="img-fluid mr-3"
                                alt=""
                              />
                            </div>
                            {this.props.t('logout')}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>}
                <button
                  onClick={() => this.setState({ dropdown: !this.state.dropdown })}
                  className="btn p-0 outline-0 position-relative min_width theme_color fw-bold hiddenInMobile"
                  type="button"
                >
                  {this.props?.userProfile?.firstName ? this.props?.userProfile?.firstName?.toUpperCase() : null}
                  {" "}
                  {this.props?.userProfile?.lastName ? this.props?.userProfile?.lastName?.toUpperCase() : null}
                  <FontAwesomeIcon
                    icon={this.state.dropdown === false ? faAngleDown : faAngleUp}
                    id="arrow"
                    className="ms-2"
                  />
                  {this.state.dropdown &&
                    <div className={`w-100 position-absolute headerDropdown bg-white p-1
                     ${cookies.get("i18next") === "en" ? "headerDropdown" : cookies.get("i18next") === "fn" ? "headerDropdown_screen" : "headerDropdown_screen"}`}>
                      <div className="bg-transparent border-0">
                        <div className="menu_spacing">
                          <Link
                            className={`nav-link border-bottom fw-normal theme_color d-flex flex-row align-items-center ${pathname === "/profile" ? "active" : ""
                              }`}
                            to="/profile"
                          >
                            <div className="navIcons">
                              <img
                                src="../assets/imgs/account.png"
                                width={20}
                                className="img-fluid mr-3"
                                alt=""
                              />
                            </div>
                            {this.props.t("profile")}
                          </Link>
                          <Link
                            className={`nav-link border-bottom fw-normal theme_color d-flex flex-row align-items-center ${pathname === "/order" ? "active" : ""
                              }`}
                            to="/order"
                          >
                            <div className="navIcons">
                              <img
                                src="../assets/imgs/order.png"
                                width={20}
                                className="img-fluid mr-3"
                                alt=""
                              />
                            </div>
                            {this.props.t("orders")}
                          </Link>
                          <Link
                            className={`nav-link border-bottom fw-normal theme_color d-flex flex-row align-items-center ${pathname === "/profile" ? "active" : ""
                              }`}
                            to="/paymentMethod"
                          >
                            <div className="navIcons">
                              <img
                                src="../assets/imgs/wallet.png"
                                width={20}
                                className="img-fluid mr-3"
                                alt=""
                              />
                            </div>
                            {this.props.t("Payment & Wallet")}
                          </Link>
                          <Link
                            className={`nav-link border-bottom fw-normal theme_color d-flex flex-row align-items-center ${pathname === "/profile" ? "active" : ""
                              }`}
                            to="/laundry_partner"
                          >
                            <div className="navIcons">
                              <img
                                src="../assets/imgs/Laundry.png"
                                width={20}
                                className="img-fluid mr-3"
                                alt=""
                              />
                            </div>
                            {this.props.t("Laundry Partner")}
                          </Link>
                          <Link
                            className={`nav-link border-bottom fw-normal theme_color d-flex flex-row align-items-center ${pathname === "/profile" ? "active" : ""
                              }`}
                            to="/earn-wosh-credit"
                          >
                            <div className="navIcons">
                              <img
                                src="../assets/imgs/woshcredit.png"
                                width={20}
                                className="img-fluid mr-3"
                                alt=""
                              />
                            </div>
                            {this.props.t("Earn Wosh Credits")}
                          </Link>
                          <a
                            className={`nav-link border-bottom fw-normal theme_color d-flex flex-row align-items-center`}
                            href="https://www.wosh.be/contact"
                            target="_blank" rel="noreferrer"
                          >
                            <div className="navIcons">
                              <img
                                src="../assets/imgs/faq.png"
                                width={20}
                                className="img-fluid mr-3"
                                alt=""
                              />
                            </div>
                            {this.props.t("FAQ & Contact Us")}
                          </a>
                          <Link
                            onClick={() => {
                              this.handleLogout();
                            }}
                            className={`nav-link fw-normal theme_color d-flex flex-row align-items-center ${pathname === "/sign-in" ? "active" : ""
                              }`}
                          >
                            <div className="navIcons">
                              <img
                                src="../assets/imgs/logout.png"
                                width={20}
                                className="img-fluid mr-3"
                                alt=""
                              />
                            </div>
                            {this.props.t('logout')}
                          </Link>
                        </div>
                      </div>
                    </div>
                  }
                </button>
              </div >
            </div >
          </nav >
        ) : (
          <nav className="navbar navbar-expand-lg navbar-light bg-transparent">
            <div className="container-fluid margin_botm">
              <Link to="/home">
                <div className="d-flex flex-row px-sm-3 px-0 pt-4">
                  <img
                    src="../assets/imgs/logoDark.png"
                    width={180}
                    className="login_logo img-fluid"
                    alt=""
                  />
                </div>
              </Link>
            </div>
          </nav>
        )
        }
        <ChangeAddress
          id={this.state.key}
          handleSubmit={() => {
            this.handleSubmit(this.state.selected)
          }}
        />
      </span>
    );
  }
}
const mapStateToProps = (state) => ({
  username: state?.auth?.user,
  deliveryAddress: state?.auth?.homeAddress,
  userProfile: state?.auth?.profileUser,
  officeAddress: state?.auth?.officeAddress,
  authUser: state?.auth?.user,
  sAddress: state?.auth?.selectedAddress,
  address: state?.cart?.home,
  hAddress: state?.auth?.homeAddress,
  oAddress: state?.auth?.officeAddress,
  editt: state?.auth?.edit,
  products: state?.auth?.sortedCategories
});
export default compose(withRouter, withTranslation(), connect(mapStateToProps, { setLoading, logout, set_profile, defaultAddress, setAddress, set_OfficeAddress, selectAddress, setSelectedAddress, fetchCartItem, getProducts }))(Header);
