import React from "react";
import "../../Header/user.css";
const ProgressStatus = (props) => {
  return (
    <>
      <div className="display_none_mobile">
        <div className="d-flex w-75 mx-auto justify-content-center align-items-center flex-sm-row flex-column mt-5 mb-4">
          <div className="progress_circle progress_circle_active current_step" />

          <div
            className={
              props.step >= 2
                ? "progress_line progress_line_active w-25"
                : "progress_line  w-25"
            }
          />
          {props?.step === 2 ? (
            <div className="main_outer_shadow">
              <div className="outer_shadow">
                <div className="progress_circle progress_circle_active current_step">
                  <div className="white_circle" />
                </div>
              </div>
            </div>
          ) : (
            <div
              className={
                props.step >= 2
                  ? "progress_circle progress_circle_active current_step"
                  : "progress_circle "
              }
            />
          )}
          <div
            className={
              props.step >= 3
                ? "progress_line progress_line_active w-25"
                : "progress_line  w-25"
            }
          />
          {props?.step === 3 ? (
            <div className="main_outer_shadow">
              <div className="outer_shadow">
                <div className="progress_circle progress_circle_active current_step">
                  <div className="white_circle" />
                </div>
              </div>
            </div>
          ) : (
            <div
              className={
                props.step >= 3
                  ? "progress_circle progress_circle_active current_step "
                  : "progress_circle "
              }
            />
          )}
          <div
            className={
              props.step >= 4
                ? "progress_line progress_line_active w-25"
                : "progress_line  w-25"
            }
          />
          {props?.step === 4 ? (
            <div className="main_outer_shadow">
              <div className="outer_shadow">
                <div className="progress_circle progress_circle_active current_step">
                  <div className="white_circle" />
                </div>
              </div>
            </div>
          ) : (
            <div
              className={
                props.step >= 4
                  ? "progress_circle progress_circle_active current_step"
                  : "progress_circle "
              }
            />
          )}
          <div
            className={
              props.step >= 5
                ? "progress_line progress_line_active w-25"
                : "progress_line  w-25"
            }
          />
          {props?.step === 5 ? (
            <div className="main_outer_shadow">
              <div className="outer_shadow">
                <div className="progress_circle progress_circle_active current_step">
                  <div className="white_circle" />
                </div>
              </div>
            </div>
          ) : (
            <div
              className={
                props.step >= 5
                  ? "progress_circle progress_circle_active current_step"
                  : "progress_circle "
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ProgressStatus;
