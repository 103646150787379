import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBalanceScaleRight,
  faMinusCircle,
} from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import Config from "../../../Config";
import { store } from "../../../..";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import SlideshowWithPagination from "react-slideshow-with-pagination";
import "./../../Header/user.css";
import {
  setQuantity,
  incDec,
  setCartItems,
  fetchCartItem,
  cartApi,
  selectAddress,
} from "../../../../store/Actions/cartActions";
import { useHistory } from "react-router-dom";
import { setAddress, setSelectedAddress, set_OfficeAddress } from "../../../../store/Actions/authActions";
import cookies from "js-cookie";

const YourItemsCheckout = (props) => {
  const history = useHistory();
  const handleDispatch = (item, sign) => {
    // props.dispatch(incDec(item, sign));
    props.dispatch(cartApi(item, sign));
    // props.dispatch(CartIncDec(item, sign));
  };
  const { t } = useTranslation();
  const [userName, setUserName] = useState(null);
  const [bool, setBool] = useState([]);
  useEffect(() => {
    setUserName(props?.authUser?.user_name);
    let msg = props?.cartItems?.filter(
      (item) => item?.name === "On Hanger Bag"
    );
    if (msg) {
      setBool(msg);
    }
  }, []);

  const setAdd = () => {
    let h = props?.hAddress?.filter((item) => {
      return item?.is_default === true;
    });
    let o = props?.oAddress?.filter((item) => {
      return item?.is_default === true;
    });

    if (h?.length > 0) {
      props.dispatch(setSelectedAddress(h[0]?.id, "home"));
      props?.dispatch(selectAddress("home"));
      // history.push("/checkout")
    }

    if (h?.length === 0 && o?.length > 0) {
      props?.dispatch(setSelectedAddress(o[0]?.id, "office"));
      props?.dispatch(selectAddress("office"));
      // history.push("/checkout")
    }

    if (h?.length === 0 && o?.length === 0) {
      props.dispatch(setSelectedAddress(props?.hAddress?.[0]?.id, "home"));
      props?.dispatch(selectAddress("home"));
      // history.push("/checkout")
    }
    history.push("/checkout")
  };

  const cartItem = props?.cartItems?.map((item, key) => {
    return (
      <>
        <div className="row">
        {/* <div className="d-flex justify-content-between"> */}
          <div className="col-6">
            <h6 className="mb-1">{cookies.get("i18next")==="en" ? item?.name : cookies.get("i18next")==="fn" ? item?.name_fr : cookies.get("i18next")==="du" ? item?.name_nl : item?.name}</h6>
          </div>
          <div className="col-2">
            <div className="d-flex align-items-center justify-content-center h_max_content">
              <button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  borderRadius: "0 0 0 0",
                  padding: 0,
                  margin: 0,
                }}
              >
                <FontAwesomeIcon
                  icon={faMinusCircle}
                  className="theme_color"
                  aria-hidden="true"
                  onClick={() => handleDispatch(item, "minus")}
                />
              </button>
              <span className="mx-2 theme_color">
                {(item?.quantity && item?.quantity === undefined) ||
                  item?.quantity === null
                  ? 0
                  : item?.quantity}
              </span>
              <button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  borderRadius: "0 0 0 0",
                  padding: 0,
                  margin: 0,
                }}
              >
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  className="theme_color"
                  aria-hidden="true"
                  onClick={() => handleDispatch(item, "plus")}
                />
              </button>
            </div>
          </div>
          <div className="col-4">
            <p className="mb-0 text-end">
              {parseFloat(item?.price)?.toFixed(2)?.replace(".", ",")}€{" "}
            </p>
          </div>
        </div>
      </>
    );
  });
  return (
    <div id="promotion_slider" className="mt-3">

      <SlideshowWithPagination
        showNumbers={false}
        showDots={true}
        showArrows={false}
        paginationMarginTop={2}
        // className="theme_shadow bg-transparent"
        className="slider_page bg-transparent rounded"
      >

        {props.sliderData.map((item, index) => (
          <>
            <div
              data-bs-toggle={item?.premium_cp === true && item?.status === 'delivery' ? "modal" : null}
              data-bs-target={item?.premium_cp === true && item?.status === 'delivery' ? "#stripeModal" : null} key={item.id} className="theme_shadow_slider w_in_mobile_desktop" style={{ borderRadius: '8px', minHeight: "130px", backgroundColor: '#E6E9FF', flexDirection: 'row', display: 'flex', }} onClick={() => props.onPressSlider(item)}>
              <div className="slider_img" style={{ backgroundColor: item?.premium_cp === true && item?.status === 'delivery' ? '#8AE3E8' : 'transparent' }}>
                <img src={item.image} style={{ marginTop: item.id === 4 ? 20 : item?.id === 3 ? 30 : 25, marginLeft: item.id === 4 ? 25 : item.id === 3 ? 20 : 10 }}
                  alt="" />
              </div>

              <div >
                <div className="promotion_heading">{item.title}</div>
                <div className="promotion_line" />
                <div className="promotion_text">{item.subTitle}</div>
              </div>
              {/* <div style={{width: 12,height:'100%',backgroundColor:'red',marginRight:10,position:'absolute',right:10,}}></div> */}
            </div>
          </>
        ))}
      </SlideshowWithPagination>

      <div className="bg_custom box my-4 theme_shadow border-0 w_335px mobile_screen">
        <div className="w-100 d-flex flex-column p-3 pb-0">
          <h3 className="theme_color font_19px m-0">{t("Your items")}</h3>
          <div className="heading-line line_dark w-25 mb-3" />
        </div>

        {/* {bag()} */}
        <div className="w-100 h_200px overflow-auto d-inline-block p-3 pt-0 border_bottom_dark">
          {props?.totalAmount > 0 || props?.cartItems?.length > 0 ? (
            cartItem
          ) : (
            <p>{t("No products in tCart right now")} </p>
          )}
          {/* {props?.totalAmount && props?.totalAmount > 0 ? (
            cartItem
          ) : (
            <p>No products in Cart right now </p>
          )} */}
        </div>
        <div className="w-100 d-flex flex-row align-items-center justify-content-between px-4 py-1">
          <h6 className="mb-0 theme_color">{t("Total")}</h6>
          <p className="mb-0 theme_color">
            {parseFloat(props.totalAmount)?.toFixed(2)?.replace(".", ",")} €
          </p>
        </div>
        {/* <Link
          to="/checkout"
          className={`w-100 btn checkout_btn ${props?.totalAmount > 0 ? "checkout_btn" : "btn_text"
            }`}
        >
          {t("checkout")}
        </Link> */}
        <div onClick={()=>{
           props?.dispatch(set_OfficeAddress());
          props?.dispatch(setAddress());
          //  setTimeout(() => {
             setAdd()
        // }, 1000)
    // setAdd()
    // setTimeout(() => {

    // }, 3000);
        }}>
        <Link
          to="/checkout"
          className={`w-100 btn checkout_btn ${
            props?.totalAmount > 0 ? "" : "disabled"
          }`}
        >
          {t("checkout")}
        </Link>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  cartItems: state?.cart?.cartItems,
  totalAmount: state.cart.totalAmount,
  username: state?.auth?.user,
  authUser: state?.auth?.user,
  hAddress: state?.auth?.homeAddress,
  oAddress: state?.auth?.officeAddress,
});

export default connect(mapStateToProps)(YourItemsCheckout);
