import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import EditOrderModal from "../../../Modals/EditOrder/index";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";

const MobileProgress = (props) => {
  const { t } = useTranslation();
  const [key, setKey] = useState(props.id);
  const [dropdown, setDropwdown] = useState(null);

  const handleDropDown = (value) => {
    setDropwdown(value);
  };
  const [orders, setOrders] = useState([]);
  const orderArray = () => {
    if (props?.current) {
      let array = props?.current?.products?.map((item, index) => {
        const result = props?.items?.find((product) => {
          if (product.id === item.product_id) {
            return product;
          }
        });
        return {
          ...result,
          quantity: item?.quantity,
          price: +result?.price * item?.quantity,
        };
      });
      setOrders(array);
    }
  };

  useEffect(() => {
    orderArray();
    setKey(props.id);
    // console.log("current products from backend", props?.current);
  }, [props]);

  return (
    <div className="w_95per ms-sm-auto mx-auto d-flex flex-sm-row flex-column ps-sm-5 ps-0 mt-4">
      <div className="w_20per d-flex flex-sm-column flex-row">
        <div className="display_none_descktop">
          <div className="h-100 d-flex justify-content-center align-items-center flex-sm-row flex-column mt-sm-5 mt-0 mb-sm-4 mb-0 ps-sm-5 ps-0">
            {/* <div>
              <div className="progress_circle progress_circle_active" />
            </div>
            <div className="progress_line w_100per progress_line_active" /> */}
            <div>
              <div className={props?.step >= 1 ? "progress_circle" : "progress_circle progress_circle_active" } />
            </div>
            <div className={props?.step === 1 ? "progress_line w_100per progress_line_active" : "progress_line w_100per"} />
          </div>
        </div>

        <div className="px-2 pb-sm-0 pb-3 mr-4">
          <h6 className="theme_color fw-bold mb-3">{t("Order confirmed")} </h6>
          {props?.step < 1 ? (
            <>
              <button
                data-bs-toggle="modal"
                data-bs-target="#editOrder"
                className="btn rounded-pill font_12px px-4 fill_btn_again theme_color mb-5"
              >
                {t("Edit order")}
              </button>
              <EditOrderModal
                history={props?.history}
                id={key}
                current={props.current}
              />
            </>
          ) : null}
          <h6 className="fw-bold theme_color small">{t("Your items")}</h6>
           {props?.current?.products?.map((item, index) => {
                  return (
                    <li className="d-flex flex-row justify-content-between align-items-center pb-1 theme_color">
                      <span className="small">{cookies.get("i18next") === "fn" ? item?.product_name : cookies.get("i18next") === "du" ? item?.product_name_nl : item?.product_name_en} </span>
                      <span className="fw-bold small">
                        {item.quantity <= 1
                          ? item.quantity
                          : item.quantity}
                      </span>
                    </li>
                  )
                })}
                <li className="d-flex flex-row justify-content-between align-items-center theme_color mt-2">
                  <span className="small">{t("Price")}</span>
                  <span className="fw-bold">
                    {parseFloat(props?.current?.subtotal)?.toFixed(2)}€
                  </span>
                </li>
        </div>
      </div>
      <div className="w_20per d-flex flex-sm-column flex-row">
        <div className="display_none_descktop">
          <div className="h-100 d-flex justify-content-center align-items-center flex-sm-row flex-column mt-sm-5 mt-0 mb-sm-4 mb-0">
            {/* <div>
              <div className="progress_circle progress_circle_active" />
            </div>
            <div className="progress_line w_80per progress_line_active" /> */}
            <div>
              <div className={props.step >= 2 ? "progress_circle progress_circle_active" : "progress_circle" } />
            </div>
            <div className={props.step === 2 ? "progress_line w_100per progress_line_active" : "progress_line w_100per"} />
          </div>
        </div>

        <div className="px-2 pb-sm-0 pb-3 ps-sm-5 ps-0">
          <button
            onClick={() => {
              handleDropDown(dropdown !== "pickup" ? "pickup" : null);
            }}
            className="btn p-0 theme_color fw-bold outline-0"
            type="button"
          >
            {t("Picked-up")}
            <FontAwesomeIcon
              icon={dropdown !== "pickup" ? faAngleDown : faAngleUp}
              id="arrow"
              className="ms-2"
              data-bs-dismiss="modal"
              aria-hidden="true"
            />
          </button>
          {dropdown === "pickup" &&
            (props.current.status === "picked_up" && props.step === 2 ? (
              <div className="bg-transparent border-0 theme_color mt-3">
                <div className="mb-2">
                  <h6 className="theme_color fw-bold small">{t("Today")}</h6>
                  <p className="theme_color_light small">
                    {props?.current?.delivery_date}
                  </p>
                </div>
                <div className="mb-2">
                  <h6 className="theme_color fw-bold small">{t("Your items")}</h6>
                  <ul className="p-0">
                    {orders.map((item, index) => {
                      return (
                        <li className="d-flex flex-row justify-content-between">
                          <span className="theme_color_light small">
                            {`${t(item?.name)}` + " : "}
                          </span>
                          <span className="theme_color_light small">
                            {item?.quantity <= 1
                              ? item?.quantity
                              : item?.quantity}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            ) : props.step < 2 ? (
              <div className="mb-2">
                {/* <h6>Status</h6> */}
                <div className="bg-transparent border-0 theme_color mt-3">
                  <div className="mb-2">
                    <h6 className="theme_color fw-bold small">{t("Date & Time")}</h6>
                    <p className="theme_color small mb-1">
                      {props?.current?.pickup_date}
                    </p>
                    <p className="theme_color small mb-1">
                      {props?.current?.pickup_slot}
                    </p>
                  </div>
                  <div className="mb-2">
                    <h6 className="theme_color fw-bold small">{t("Address")}</h6>
                    <p className="theme_color small">
                      {props?.current?.delivery_address
                        ? props?.current?.delivery_address
                        : props?.current?.collection_point_address}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="bg-transparent border-0 theme_color mt-3">
                <div className="mb-2">
                  <h6 className="theme_color fw-bold small">{t("Date & Time")}</h6>
                  <p className="theme_color small mb-1">
                    {props?.current?.pickup_date}
                  </p>
                  <p className="theme_color small">
                    {props?.current?.pickup_slot}
                  </p>
                </div>
                <div className="mb-2">
                  <h6 className="theme_color fw-bold small">{t("Address")}</h6>
                  <p className="theme_color small">
                    {props?.current?.delivery_address
                      ? props?.current?.delivery_address
                      : props?.current?.collection_point_address}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="w_20per d-flex flex-sm-column flex-row">
        <div className="display_none_descktop">
          <div className="h-100 d-flex justify-content-center align-items-center flex-sm-row flex-column mt-sm-5 mt-0 mb-sm-4 mb-0">
            {/* <div>
              <div className="progress_circle progress_circle_active" />
            </div>
            <div className="progress_line w_80per progress_line_active" /> */}
            <div>
              <div className={props.step >= 3 ? "progress_circle progress_circle_active" : "progress_circle" } />
            </div>
            <div className={props.step === 3 ? "progress_line w_100per progress_line_active" : "progress_line w_100per"} />
          </div>
        </div>

        <div className="px-2 pb-sm-0 pb-3 ps-sm-5 ps-0">
          <button
            onClick={() => {
              handleDropDown(dropdown !== "processed" ? "processed" : null);
            }}
            className="btn p-0 theme_color fw-bold outline-0"
            type="button"
          >
            {t("Processed")}
            <FontAwesomeIcon
              icon={dropdown !== "processed" ? faAngleDown : faAngleUp}
              id="arrow"
              className="ms-2"
            />
          </button>
          {dropdown === "processed" &&
            (props.current.status === "processed" && props.step === 3 ? (
              <div className="bg-transparent border-0 theme_color mt-3">
                <div className="mb-2">
                  <h6 className="theme_color fw-bold small">{t("Today")}</h6>
                  <p className="theme_color small">
                    {props?.current?.delivery_date}
                  </p>
                </div>
                <div className="mb-2">
                  <h6 className="theme_color fw-bold small">{t("Your items")}</h6>
                  <ul className="p-0">
                    {orders.map((item, index) => {
                      return (
                        <li className="d-flex flex-row justify-content-between">
                          <span className="theme_color small">
                            {`${t(item?.name)}` + " : "}
                          </span>
                          <span>
                            {item?.quantity <= 1
                              ? item?.quantity + " item"
                              : item?.quantity + " items"}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            ) : props.step < 3 ? (
              <div className="mb-2">
                {/* <h6>Status</h6> */}
                <p className="theme_color small theme_color">
                  {t(
                    "Our experts will review your order and make sure your laundry receives appropriate care."
                  )}
                </p>
              </div>
            ) : (
              <div className="mb-2">
                {/* <h6>Status</h6> */}
                <p className="theme_color small theme_color">
                  {t("Your order has been processed !")}
                </p>
              </div>
            ))}
        </div>
      </div>
      <div className="w_20per d-flex flex-sm-column flex-row">
        <div className="display_none_descktop">
          <div className="h-100 d-flex justify-content-center align-items-center flex-sm-row flex-column mt-sm-5 mt-0 mb-sm-4 mb-0">
            {/* <div>
              <div className="progress_circle progress_circle_active" />
            </div>
            <div className="progress_line w_80per progress_line_active" /> */}
            <div>
              <div className={props.step >= 4 ? "progress_circle progress_circle_active" : "progress_circle" } />
            </div>
            <div className={props.step === 4 ? "progress_line w_100per progress_line_active" : "progress_line w_100per"} />
          </div>
        </div>

        <div className="px-2 pb-sm-0 pb-3 ps-sm-5 ps-0">
          <button
            onClick={() => {
              handleDropDown(dropdown !== "paid" ? "paid" : null);
            }}
            className="btn p-0 theme_color fw-bold outline-0"
            type="button"
          >
            {t("Paid")}
            <FontAwesomeIcon
              icon={dropdown !== "paid" ? faAngleDown : faAngleUp}
              id="arrow"
              className="ms-2"
            />
          </button>
          {dropdown === "paid" &&
            (props.current.status === "paid" && props.step === 4 ? (
              <div className="bg-transparent border-0 theme_color mt-3">
                <div className="mb-2">
                  <h6 className="theme_color fw-bold small">{t("Today")}</h6>
                  <p className="theme_color small">
                    {props?.current?.delivery_date}
                  </p>
                </div>
                <div className="mb-2">
                  <h6 className="theme_color fw-bold samll">{t("Your items")}</h6>
                  <ul className="p-0">
                    {orders.map((item, index) => {
                      return (
                        <li className="d-flex flex-row justify-content-between">
                          <span className="theme_color small">
                            {`${t(item?.name)}` + " : "}
                          </span>
                          <span>
                            {item?.quantity <= 1
                              ? item?.quantity + " item"
                              : item?.quantity + " items"}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            ) : props.step < 4 ? (
              <div className="mb-2">
                {/* <h6>Status</h6> */}
                <p className="theme_color small theme_color">
                  {t(
                    "The total amount will be deducted from your account once our experts finished processing your order."
                  )}
                </p>
              </div>
            ) : (
              <div className="mb-2">
                {/* <h6>Status</h6> */}
                <p className="theme_color small theme_color">
                  {t("You order has been paid !")}
                </p>
              </div>
            ))}
        </div>
      </div>
      <div className="w_20per d-flex flex-sm-column flex-row">
        <div className="display_none_descktop">
          <div className="h-100 d-flex align-items-center flex-sm-row flex-column mt-sm-5 mt-0 mb-sm-4 mb-0">
            {/* <div>
              <div className="progress_circle progress_circle_active" />
            </div> */}
            <div>
              <div className={props.step >= 5 ? "progress_circle progress_circle_active" : "progress_circle" } />
            </div>
            {/* <div className={props.step === 2 ? "progress_line w_100per progress_line_active" : "progress_line w_100per"} /> */}
          </div>
        </div>
        <div className="px-2 pb-sm-0 pb-3 ps-sm-5 ps-0">
          <button
            onClick={() => {
              handleDropDown(dropdown !== "delivery" ? "delivery" : null);
            }}
            className="btn p-0 theme_color fw-bold outline-0"
            type="button"
          >
            {t("Delivery")}
            <FontAwesomeIcon
              icon={dropdown !== "delivery" ? faAngleDown : faAngleUp}
              id="arrow"
              className="ms-2"
            />
          </button>

          {dropdown === "delivery" &&
            (props.current.status === "picked_up" && props.step === 5 ? (
              <div className="bg-transparent border-0 theme_color mt-3">
                <div className="mb-2">
                  <h6 className="theme_color fw-bold small">{t("Today")}</h6>
                  <p className="theme_color small">
                    {props?.current?.delivery_date}
                  </p>
                </div>
                <div className="mb-2">
                  <h6 className="theme_color fw-bold small">{t("Your items")}</h6>
                  <ul className="p-0">
                    {orders.map((item, index) => {
                      return (
                        <li className="d-flex flex-row justify-content-between">
                          <span className="theme_color small">
                            {`${t(item?.name)}` + " : "}
                          </span>
                          <span>
                            {item?.quantity <= 1
                              ? item?.quantity + " item"
                              : item?.quantity + " items"}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            ) : props.step < 5 ? (
              <div className="mb-2">
                {/* <h6>Status</h6> */}
                <div className="bg-transparent border-0 theme_color mt-3">
                  <div className="mb-2">
                    <h6 className="theme_color fw-bold small">{t("Date & Time")}</h6>
                    <p className="theme_color small">
                      {props?.current?.delivery_date}
                    </p>
                    <p className="theme_color small">
                      {props?.current?.delivery_slot}
                    </p>
                  </div>
                  <div className="mb-2">
                    <h6 className="theme_color fw-bold small">{t("Address")}</h6>
                    <p className="theme_color small">
                      {props?.current?.delivery_address
                        ? props?.current?.delivery_address
                        : props?.current?.collection_point_address}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="mb-2">
                {/* <h6>Status</h6> */}
                <div className="bg-transparent border-0 theme_color mt-3">
                  <div className="mb-2">
                    <h6 className="theme_color fw-bold small">{t("Date & Time")}</h6>
                    <p className="theme_color small">
                      {props?.current?.delivery_date}
                    </p>
                    <p className="theme_color small">
                      {props?.current?.delivery_slot}
                    </p>
                  </div>
                  <div className="mb-2">
                    <h6 className="theme_color fw-bold small">{t("Address")}</h6>
                    <p className="theme_color small">
                      {props?.current?.delivery_address
                        ? props?.current?.delivery_address
                        : props?.current?.collection_point_address}
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    items: state?.cart?.mockItems,
  };
};

export default connect(mapStateToProps)(MobileProgress);
