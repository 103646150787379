import React from "react";
import Header from "../Header";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./../Header/user.css";
import TabContent from "./TabContent/index";
import { withTranslation } from "react-i18next";
// import { location } from "react-router-dom";
class Order extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      myParam :this.props?.location?.state?.pastOrder
    };
  }
  render() {
    const { t } = this.props;
    return (
      <div>
        <div className="top_right">
          <img src="/assets/imgs/bubble_3.png" />
        </div>
        <div className="bottom_right">
          <img src="/assets/imgs/bubble_2.png" className="img-fluid" />
        </div>
        <div className="middle_left">
          <img src="/assets/imgs/bubble.png" />
        </div>
        {/* <Header isNav={true} /> */}
        <div className="w-100" />
        <div id="body">
          <div className="container z_index_one scroll_tab_content_new_2" id="hover_none">
            {/* <ProgressBar step={2} /> */}
            <ul
              className="nav nav-tabs second_tabs order_tabs mx-auto mt-5"
              id="myTab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <a
                  className={`nav-link px-4 py-2 h-100 ${this.state.myParam ? null  : 'active'} `}
                  id="home-tab"
                  data-bs-toggle="tab"
                  href="#body-laundry"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                >
                  <h3 className="fs-6 mb-0 fw-bold">{t("current order")}</h3>
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className={`nav-link px-4 py-2 h-100 ${this.state.myParam ? 'active'  : null} `}
                  id="profile-tab"
                  data-bs-toggle="tab"
                  href="#home-laundry"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                >
                  <h3 className="fs-6 mb-0 fw-bold">{t("past order")}</h3>
                </a>
              </li>
            </ul>
            <TabContent history={this.props.history} myParam={this.state.myParam}/>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(Order);
