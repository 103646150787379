import Config from "../../components/Config";
import Axios from "axios";
import { store } from "../../index";
import cookies from "js-cookie";
import storage from "redux-persist/lib/storage";
import { connect } from "react-redux";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_ERROR = "SET_ERROR";
export const IS_LOADING = "IS_LOADING";
export const SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD";
export const SET_DELIVERY_ADDRESS = "SET_DELIVERY_ADDRESS";
export const SET_OFFICE_ADDRESS = "SET_OFFICE_ADDRESS";
export const SET_SELECTED_ADDRESS = "SET_SELECTED_ADDRESS";
export const SET_SELECTED_CARD = "SET_SELECTED_CARD";
export const SET_PAYMENT_ID = "SET_PAYMENT_ID";
export const SET_PTIME = "SET_PTIME";
export const SET_DTIME = "SET_DTIME";
export const SET_RATING = "SET_RATING";
export const SET_DESCRIPTION = "SET_DESCRIPTION";
export const SET_WALLET = "SET_WALLET";
export const SET_CURRENT = "SET_CURRENT";
export const SET_INDEX = "SET_INDEX";
export const SET_RENDER = "SET_RENDER";
export const SET_EDIT = "SET_EDIT";
export const SET_CURRENT_ID = "SET_CURRENT_ID";
export const SET_CLEAR_STATE = "SET_CLEAR_STATE";
export const SET_CLEAR = "SET_CLEAR";
export const SET_CURRENT_LANG = "SET_CURRENT_LANG";
export const IS_ACTIVE = "IS_ACTIVE";
export const SET_PROFILE = "SET_PROFILE";
export const SET_DEFAULT = "SET_DEFAULT";

export function setCurrentUser(user) {
  return {
    type: SET_CURRENT_USER,
    user,
  };
}
export function setIsActive(bool) {
  return {
    type: IS_ACTIVE,
    payload: bool,
  };
}
export function setCurrentLocale(user) {
  return {
    type: SET_CURRENT_LANG,
    user,
  };
}
export function setCurrentId(id) {
  return {
    type: SET_CURRENT_ID,
    id,
  };
}
export function setAllClear() {
  return {
    type: SET_CLEAR,
  };
}

export function setEdit(val) {
  return {
    type: SET_EDIT,
    val,
  };
}
export function setState() {
  return {
    type: SET_CLEAR_STATE,
  };
}

export function setLoading(bool) {
  return {
    type: IS_LOADING,
    payload: bool
  };
}

export function forceRender(val) {
  return {
    type: SET_RENDER,
    val,
  };
}
export function setCurrent(current) {
  return {
    type: SET_CURRENT,
    current,
  };
}
export function setIndex(index) {
  return {
    type: SET_INDEX,
    index,
  };
}
export function setWallet(wallet) {
  return {
    type: SET_WALLET,
    wallet,
  };
}
export function setDescription(desc) {
  return {
    type: SET_DESCRIPTION,
    desc,
  };
}
export function setRating(rating) {
  return {
    type: SET_RATING,
    rating,
  };
}

export function setPaymentCard(card) {
  return {
    type: SET_SELECTED_CARD,
    card,
  };
}

export function setDTime(time) {
  return {
    type: SET_DTIME,
    time,
  };
}
export function setPaymentMethod(method) {
  return {
    type: SET_PAYMENT_METHOD,
    method,
  };
}

export function setPTime(time) {
  return {
    type: SET_PTIME,
    time,
  };
}
export function setPaymentId(id) {
  return {
    type: SET_PAYMENT_ID,
    id,
  };
}
export function setSelectedAddress(address, atype) {
  return {
    type: SET_SELECTED_ADDRESS,
    address,
    atype,
  };
}

export function setDeliveryAddress(address) {
  return {
    type: SET_DELIVERY_ADDRESS,
    address: address,
  };
}
export function setOfficeAddress(address) {
  return {
    type: SET_OFFICE_ADDRESS,
    address: address,
  };
}

export function setProfile(profile) {
  return {
    type: SET_PROFILE,
    profile,
  };
}

export function setDefault(is_default) {
  return {
    type: SET_DEFAULT,
    is_default,
  };
}

export function setAddress() {
  return async function (dispatch) {
    try {
      let address = await Axios({
        url: `${Config.apiURL}/customer/delivery_addresses`,
        method: "get",
        headers: {
          uid: store?.getState()?.auth?.user?.user_uid,
          client: store?.getState()?.auth?.user?.user_client,
          "access-token": store?.getState()?.auth?.user?.user_access_token,
        },
      });
      const deliveryAddress = address?.data?.delivery_addresses?.filter(
        (address) => {
          return address?.collection_times;
        }
      );
      // const deliveryAddress = await address?.data?.delivery_addresses;
      dispatch(setDeliveryAddress(deliveryAddress));
      dispatch(setSelectedAddress(deliveryAddress))
      return deliveryAddress;
    } catch (err) {
      if (err?.response?.status === 401) {
        dispatch(logout())
      }
      return err;
    }
  };
}

export function set_profile() {
  return async function (dispatch) {
    try {
      let response = await Axios({
        url: `${Config.apiURL}/customer/profiles`,
        method: "get",
        headers: {
          uid: store?.getState()?.auth?.user?.user_uid,
          client: store?.getState()?.auth?.user?.user_client,
          "access-token": store?.getState()?.auth?.user?.user_access_token,
        },
      });
      const user = {
        firstName: response?.data?.profile?.first_name,
        lastName: response?.data?.profile?.last_name,
        company: response?.data?.profile?.company_name
      }
      // const profile = await response?.data?.profile?.first_name;
      // console.log(user_name)
      dispatch(setProfile(user));
      return response;
    } catch (err) {
      if (err?.response?.status === 401) {
        dispatch(logout())
      }
      return err;
    }
  };
}

export function defaultAddress(address, props) {
  return async function (dispatch) {
    try {
      let response = await Axios({
        url: `${Config.apiURL}/customer/default_addresses`,
        method: "POST",
        data: {
          id: address.id,
          address_type: address.type
        },
        headers: {
          uid: store?.getState()?.auth?.user?.user_uid,
          client: store?.getState()?.auth?.user?.user_client,
          "access-token": store?.getState()?.auth?.user?.user_access_token,
        },
      });
      // console.log("dsf", response)
      dispatch(setSelectedAddress(response));
      // window.location.replace("/use-wosh");
      return response;
    } catch (err) {
      if (err?.response?.status === 401) {
        dispatch(logout())
      }
      return err;
    }
  };
}

export function set_OfficeAddress() {
  return async function (dispatch) {
    try {
      let address = await Axios({
        url: `${Config.apiURL}/customer/collection_points`,
        method: "get",
        headers: {
          uid: store?.getState()?.auth?.user?.user_uid,
          client: store?.getState()?.auth?.user?.user_client,
          "access-token": store?.getState()?.auth?.user?.user_access_token,
        },
      });
      const deliveryAddress = address?.data?.collection_points?.filter(
        (address) => {
          return address?.collection_times;
        }
      );
      dispatch(setOfficeAddress(deliveryAddress));
      dispatch(setSelectedAddress(deliveryAddress))
      return deliveryAddress;
    } catch (err) {
      if (err?.response?.status === 401) {
        dispatch(logout())
      }
      return err;
    }
  };
}

export function get_paymentId() {
  return async function (dispatch) {
    try {
      let response = await Axios({
        url: `${Config.apiURL}/customer/payment_methods`,
        method: "get",
        headers: {
          uid: store?.getState()?.auth?.user?.user_uid,
          client: store?.getState()?.auth?.user?.user_client,
          "access-token": store?.getState()?.auth?.user?.user_access_token,
        },
      });
      const id = await response?.data?.payment_method;
      dispatch(setPaymentId(id));
      return response;
    } catch (err) {
      if (err?.response?.status === 401) {
        dispatch(logout())
      }
      return err;
    }
  };
}

export function setError(error) {
  return {
    type: SET_ERROR,
    error: error,
  };
}

export function login(data) {
  const header = new Headers();
  header["Access-Control-Allow-Origin"] = "*";
  return async function (dispatch) {
    try {
      let response = await Axios.post(
        `${Config.authURL}/auth/sign_in`,
        data,
        Config.headers
      );
      // cookies.set(
      //   "i18next",
      //   response.data.data.locale === "en"
      //     ? "en"
      //     : response.data.data.locale === "fr"
      //       ? "fn"
      //       : "du"
      // );
      const user = {
        user_uid: response.headers.uid,
        user_access_token: response.headers["access-token"],
        user_client: response.headers.client,
        user_id: response.data.data.id,
        user_name: response.data.data.first_name,
        user_locale: cookies.get("i18next") === "du" ? "nl" : cookies.get("i18next") === "fn" ? "fr" : "en",
        referral_code:response.data.data.referral_code
      };
      localStorage.setItem(
        "user_client",
        JSON.stringify(response.headers.client)
      );
      localStorage.setItem("user_uid", JSON.stringify(response.headers.uid));
      localStorage.setItem("user_id", JSON.stringify(response.data.data.id));
      localStorage.setItem(
        "user_name",
        JSON.stringify(response.data.data.first_name)
      );
      localStorage.setItem(
        "user_access-token",
        JSON.stringify(response.headers["access-token"])
      );
      await Axios({
        url: `${Config.authURL}/auth`,
      method: "PUT",
      data: {
        locale: user.user_locale
      },
      headers: {
        uid: user.user_uid,
        client: user.user_client,
        "access-token": user.user_access_token,
      },
      })
      let address = await Axios({
        url: `${Config.apiURL}/customer/collection_points`,
        method: "get",
        headers: {
          uid: user.user_uid,
          client: user.user_client,
          "access-token": user.user_access_token,
        },
      });
      const deliveryAddress = address?.data?.collection_points?.filter(
        (address) => {
          return address?.collection_times;
        }
      );
      dispatch(setOfficeAddress(deliveryAddress));
      address = await Axios({
        url: `${Config.apiURL}/customer/delivery_addresses`,
        method: "get",
        headers: {
          uid: user.user_uid,
          client: user.user_client,
          "access-token": user.user_access_token,
        },
      });
      const dAddress = await address?.data?.delivery_addresses;
      dispatch(setDeliveryAddress(dAddress))
      dispatch(setCurrentUser(user));
      // debugger
      // window.location.replace("/home");
      // localStorage.removeItem("auth_error");
      // console.log({ response });
      return response;
    } catch (err) {
      let msg = ""

      if (err?.response?.data?.message?.user[0] === "Try to login using Google Sign-up.") {
        cookies.get("i18next") === "du" ?
        msg = "Probeer in te loggen met Google Sign-up." :
        cookies.get("i18next") === "fn" ? msg = "Essayez de vous connecter à l'aide de Google Sign-up." :
        msg = err?.response?.data?.message?.user[0]
      }
      else if(err.response.data?.errors[0] === "Invalid Password. Please try again."){
        cookies.get("i18next") === "du" ?
        msg = "Onjuist wachtwoord. Probeer het opnieuw." :
        cookies.get("i18next") === "fn" ? msg = "Mot de passe invalide. Veuillez réessayer." :
        msg = err?.response?.data?.errors[0]
      }
      else if(err.response.data?.errors[0] === "Invalid login credentials. Please try again."){
        cookies.get("i18next") === "du" ?
        msg = "Ongeldige aanmeldingsgegevens. Probeer het opnieuw." :
        cookies.get("i18next") === "fn" ? msg = "Identifiants de connexion invalides. Veuillez réessayer." :
        msg = err?.response?.data?.errors[0]
      }
      else {
        msg = err?.response?.data
          ? err?.response?.data?.errors[0]
          : "Something went wrong, please try with a different email address.";
      }

      dispatch(setError(msg));
      // localStorage.setItem("auth_error", JSON.stringify(error.message));
      // return error;
    }
  };
}

export function googleLogin(response) {
  var token = response;
  var data = {
    id_token: token.credential,
  };
  const header = new Headers();
  header["Access-Control-Allow-Origin"] = "*";
  return async (dispatch) => {
    try {
      let response = await Axios.post(
        `${Config.socialLogin}`,
        data,
        Config.headers
      );
      const user = {
        user_uid: response.data.headers.uid,
        user_access_token: response.data.headers["access-token"],
        user_client: response.data.headers.client,
        user_id: response.data.user.id,
        user_name: response.data.user.first_name,
        user_locale: response.data.user.locale,
        referral_code: response.data.user.referral_code,
      };
      localStorage.setItem(
        "user_client",
        JSON.stringify(response.data.headers.client)
      );
      localStorage.setItem("user_uid", JSON.stringify(response.data.headers.uid));
      localStorage.setItem("user_id", JSON.stringify(response.data.user.id));
      localStorage.setItem(
        "user_name",
        JSON.stringify(response.data.user.first_name)
      );
      localStorage.setItem(
        "user_access-token",
        JSON.stringify(response.data.headers["access-token"])
      );
      let address = await Axios({
        url: `${Config.apiURL}/customer/collection_points`,
        method: "get",
        headers: {
          uid: user.user_uid,
          client: user.user_client,
          "access-token": user.user_access_token,
        },
      });
      const deliveryAddress = address?.data?.collection_points?.filter(
        (address) => {
          return address?.collection_times;
        }
      );
      dispatch(setOfficeAddress(deliveryAddress));
      address = await Axios({
        url: `${Config.apiURL}/customer/delivery_addresses`,
        method: "get",
        headers: {
          uid: user.user_uid,
          client: user.user_client,
          "access-token": user.user_access_token,
        },
      });
      const dAddress = await address?.data?.delivery_addresses;
      dispatch(setDeliveryAddress(dAddress));
      dispatch(setCurrentUser(user));
      return response;
    } catch (err) {
      let msg = err?.response?.data
        ? "Invalid email address. Please try another or create an account."
        : "Something went wrong, please try with a different email address.";

      dispatch(setError(msg));
    }

  }
}

export function appleLogin(response) {
  let data = {
    authorizationCode:response.code,
    identityToken:response.id_token,
    givenName:'',
    familyName:''
  }
  const header = new Headers();
  header["Access-Control-Allow-Origin"] = "*";
  return async (dispatch ) => {
    try {
      let response = await Axios.post(
        `${Config.appleLogin}`,
        data,
        Config.headers
        );
    const user = {
      user_uid: response.data.headers.uid,
      user_access_token: response.data.headers["access-token"],
      user_client: response.data.headers.client,
      user_id: response.data.user.id,
      user_name: response.data.user.first_name,
      user_locale: response.data.user.locale,
    };
    localStorage.setItem(
      "user_client",
      JSON.stringify(response.data.headers.client)
      );
      localStorage.setItem("user_uid", JSON.stringify(response.data.headers.uid));
      localStorage.setItem("user_id", JSON.stringify(response.data.user.id));
      localStorage.setItem(
        "user_name",
      JSON.stringify(response.data.user.first_name)
    );
    localStorage.setItem(
      "user_access-token",
      JSON.stringify(response.data.headers["access-token"])
      );
       let address = await Axios({
        url: `${Config.apiURL}/customer/collection_points`,
        method: "get",
        headers: {
          uid: user.user_uid,
          client: user.user_client,
          "access-token": user.user_access_token,
        },
      });
      const deliveryAddress = address?.data?.collection_points?.filter(
        (address) => {
          return address?.collection_times;
        }
      );
      dispatch(setOfficeAddress(deliveryAddress));
       address = await Axios({
        url: `${Config.apiURL}/customer/delivery_addresses`,
        method: "get",
        headers: {
          uid: user.user_uid,
          client: user.user_client,
          "access-token": user.user_access_token,
        },
      });
      const dAddress = await address?.data?.delivery_addresses;
      dispatch(setDeliveryAddress(dAddress));
      dispatch(setCurrentUser(user));
    return response;
  } catch (err) {
    let msg = err?.response?.data
      ? "Invalid email address. Please try another or create an account."
      : "Something went wrong, please try with a different email address.";

    dispatch(setError(msg));
  }

}
}


export function register(data, props) {
  // debugger;
  return async (dispatch) => {
    try {
      // debugger;
      let response = await Axios.post(`${Config.authURL}/auth`, data);
      cookies.set(
        "i18next",
        response.data.data.locale === "en"
          ? "en"
          : response.data.data.locale === "fr"
            ? "fn"
            : "du"
      );
      // const email = await response.data.data.email;
      const user = {
        user_uid: response.headers.uid,
        user_access_token: response.headers["access-token"],
        user_client: response.headers.client,
        user_name: response.data.data.first_name,
        user_locale: response.data.data.locale,
        user_id: response.data.data.id,
      };
      localStorage.setItem(
        "user_client",
        JSON.stringify(response.headers.client)
      );
      localStorage.setItem("user_uid", JSON.stringify(response.headers.uid));
      localStorage.setItem(
        "user_name",
        JSON.stringify(response.data.data.first_name)
      );
      localStorage.setItem("user_id", JSON.stringify(response.data.data.id));
      localStorage.setItem(
        "user_access-token",
        JSON.stringify(response.headers["access-token"])
      );
      dispatch(setCurrentUser(user));
      // window.location.pathname.replace("/use-wash");
      localStorage.setItem("progress", "true");
      // window.location.replace("/use-wosh");
      props.history.push("/use-wosh", { myLove: 'Pakistan' });
    } catch (err) {
      // console.log(
      //   "action error => ",
      //   err.response.data
      //     ? err.response.data.errors.full_messages
      //     : err.response.statusText
      // );
      let msg = err.response.data
        ? err.response.data.errors.full_messages
        : "Something went wrong, please try with a different email address.";
      // console.log("action error => ", { msg });
      dispatch(setError(msg));
    }
  };
}

export function logout(history) {
  return async (dispatch) => {
    try {
      let response = await Axios({
        url: `${Config.authURL}/auth/sign_out`,
        method: "DELETE",
        headers: {
          uid: store?.getState()?.auth?.user?.user_uid,
          client: store?.getState()?.auth?.user?.user_client,
          "access-token": store?.getState()?.auth?.user?.user_access_token,
        },
      });
      history.push("/sign-in")
      setTimeout(() => {
        window.localStorage.clear();
      dispatch(setAllClear());
      }, 100)
      return response;
    } catch (err) {
      dispatch(setAllClear());
    }
  };
}

const mapStateToProps = (state) => ({
  deliveryAddress: state.auth?.homeAddress,
  officeAddress: state.auth?.officeAddress,
});

export default (googleLogin, defaultAddress)
