import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../../Header/user.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import StarRating from "../Star";
import Config from "../../../Config/index";
import Axios from "axios";
import ProgressStatus from "../ProgressStatus";
import MobileProgress from "../ProgressStatus/MobileProgress";
import PastOrder from "./PastOrders/index";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { setCurrent } from "../../../../store/Actions/authActions";

class TabContent extends React.Component {
  constructor() {
    super();
    this.state = {
      dropDown: null,
      rating: 0,
      error: true,
      index: 0,
      current: [],
      paginationNumbers: 0,
      past: null,
      step: "",
      currentTab: 0,
    };
  }
  async componentDidMount() {
    try {
      const response = await Axios({
        url: `${Config.apiURL}/customer/orders`,
        method: "GET",
        headers: {
          uid: this.props?.authUser?.user_uid,
          client: this.props?.authUser?.user_client,
          "access-token": this.props?.authUser?.user_access_token,
        },
      });
      response.data &&
        this.props?.dispatch(
          setCurrent(response.data.current_orders?.products)
        );

      this.setState({
        current: response.data.current_orders || [],
        error: false,
        // paginationNumbers: response.data.current_orders.length > 3 ? ['0', '1', '3'] : response.data.current_orders
      });
    } catch (error) {
      this.setState({ error: true });
    }
  }

  handleDropDown = (value) => {
    this.setState({
      dropDown: value,
    });
  };
  onStarClick(nextValue, prevValue, name) {
    this.setState({ rating: nextValue });
  }
  handleTabs = (value) => {
    this.setState({
      currentTab: value,
    });
    // console.log(this.state.currentTab)
  };

  render() {
    const { t } = this.props;

    // let items = [];
    // let leftSide = this.state?.currentTab - 3;
    // if (leftSide <= 0) leftSide = 1;
    // let rightSide = this.state?.currentTab + 3;
    // if (rightSide > this.state.current?.length)
    //   rightSide = this.state?.current?.length;
    // for (
    //   let number = this.state?.currentTab + 1;
    //   number <= rightSide;
    //   number++
    // ) {
    //   items.push(
    //     <div
    //       style={{ cursor: "pointer" }}
    //       key={number}
    //       className={
    //         number === this.state?.currentTab + 1
    //           ? "round-effect active"
    //           : "round-effect"
    //       }
    //       onClick={() => {
    //         this.setState({ ...this.state, currentTab: number - 1 });
    //       }}
    //     >
    //       {number}
    //     </div>
    //   );
    // }

    // const nextPage = () => {
    //   if (this.state.currentTab < this.state.current?.length - 1) {
    //     this.setState({
    //       currentTab: this.state.currentTab + 1,
    //     });
    //   }
    // };

    // const prevPage = () => {
    //   if (this.state.currentTab >= 1) {
    //     this.setState({
    //       currentTab: this.state.currentTab - 1,
    //     });
    //   }
    // };
    return (
      <div>
        <div className="tab-content" id="myTabContent">
          <div
            className={this.props.myParam ? "tab-pane fade  py-4 flex-sm-column flex-row order_page" : "tab-pane fade show active py-4 flex-sm-column flex-row order_page" }
            id="body-laundry"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            {this.state.current && this.state.current?.length > 0 && (
              <div className="container overflow-hidden">
                <ul
                  className={
                    this.state.current?.length <= 5
                      ? "nav nav-tabs second_tabs order_tabs scroll_horizon mt-5 widthFitContent mx-auto"
                      : "nav nav-tabs second_tabs order_tabs scroll_horizon mt-5"
                  }
                  id="myTab"
                  role="tablist"
                >
                  {this.state.current && this.state.current?.length > 0
                    ? this.state.current?.map((current, index) => {
                      return (
                        <li
                          key={index}
                          className="nav-item bg-white"
                          role="presentation"
                        >
                          <a
                            className={
                              index == this.state.currentTab
                                ? "nav-link px-4 py-2 h-100 active"
                                : "min_width nav-link px-4 py-2 h-100"
                            }
                            onClick={() => {
                              this.handleTabs(index);
                            }}
                          >
                            <h3 className="fs-5 mb-0">
                              {this.props.t("order") + " " + (1 + index)}
                            </h3>
                          </a>
                        </li>
                      );
                    })
                    : null}
                </ul>
                {/* <TabContent history={this.props.history} /> */}
              </div>
            )}
            {this.state.current && this.state.current?.length === 0 ? (
              <h6 className="theme_color text-center">
                {" "}
                {this.props.t("No Current Order")}
              </h6>
            ) : (
              <>
                {this.state.current && this.state.current.length > 0 ? (
                  <div
                  // key={index}
                  // className={index != this.state.currentTab ? "d-none" : " " }
                  // id={"tabData"+index}
                  // role={"tabpane"+index}
                  // aria-labelledby={"tab-"+index}
                  >
                    {/* {this.state.currentTab} */}
                    <ProgressStatus
                      step={
                        this.state.current[this.state.currentTab]?.status ===
                          "confirmed"
                          ? ""
                          : this.state.current[this.state.currentTab]
                            ?.status === "picked_up" ||
                            this.state.current[this.state.currentTab]
                              ?.status === "dropped_at_laundry"
                            ? 2
                            : this.state.current[this.state.currentTab]
                              ?.status === "processed"
                              ? 3
                              : this.state.current[this.state.currentTab]
                                ?.status === "paid" || this.state.current[this.state.currentTab]?.status === "ready_for_delivery"
                                ? 4
                                : this.state.current[this.state.currentTab]
                                  ?.status === "delivered"
                                  ? 5
                                  : ""
                      }
                    />
                    <MobileProgress
                      // key={index}
                      // id={index}
                      current={this.state.current[this.state.currentTab]}
                      step={
                        this.state.current[this.state.currentTab]?.status ===
                          "confirmed"
                          ? ""
                          : this.state.current[this.state.currentTab]
                            ?.status === "picked_up" ||
                            this.state.current[this.state.currentTab]
                              ?.status === "dropped_at_laundry"
                            ? 2
                            : this.state.current[this.state.currentTab]
                              ?.status === "processed"
                              ? 3
                              : this.state.current[this.state.currentTab]
                                ?.status === "paid"
                                ? 4
                                : this.state.current[this.state.currentTab]
                                  ?.status === "delivered"
                                  ? 5
                                  : ""
                      }
                      history={this.props.history}
                    />
                  </div>
                ) : null}
              </>
            )}
            {/* {this.state.current && this.state.current?.length > 0 && (
              <div className="paginate-ctn d-flex flex-row justify-content-center align-items-center mt-4">
              <div
                className="round-effect"
                onClick={prevPage}
                style={{ cursor: "pointer" }}
              >
                {" "}
                &lsaquo;{" "}
              </div>
              {items ? items : null}
              <div
                className="round-effect"
                onClick={nextPage}
                style={{ cursor: "pointer" }}
              >
                {" "}
                &rsaquo;{" "}
              </div>
            </div>
            )} */}
          </div>
          <div
            className={this.props.myParam ? "tab-pane fade show active py-4 flex-sm-column flex-row order_page" :"tab-pane fade py-4"}
            id="home-laundry"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <PastOrder history={this.props?.history} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    authUser: state?.auth?.user,
  };
};

export default compose(withTranslation(), connect(mapStateToProps))(TabContent);
