import React from "react";
import { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../../Header/user.css";
import { connect } from "react-redux";
import { addItemsToCart } from "../../../../store/Actions/cartActions";
import { OnHangersTab } from "./onHangers";
import cookies from "js-cookie"
import { KiloTab } from "./Kilo";
import { IroningTab } from "./Ironing";
import { FavoriteTab } from "./Favourite";
import { FoldedTab } from "./Folded";
import { t } from "i18next";
import { isArray, set } from "lodash";
import Config from "../../../Config";
import Footer from "../Footer/Footer";
import * as ReactBootStrap from 'react-bootstrap';

const TabContent = (props) => {
  // const [count, setCount] = useS;
  const [selectedSubTabData, setSelectedSubTabData] = useState([]);
  const [selectedKey, setSelectedKey] = useState('');
  // const [loading, setLoading] = useState(false)
  const [sortCategoryName, setSortCategoryName] = useState([])
  let translatedObjen, translatedObjfr, translatedObjdu;

  useEffect(() => {
    // setTimeout(() => {
    if (props.data) {

    const tempArray = Object.entries(props.data);
    tempArray.sort((a, b) => {
      const orderA = a[1][0]?.category?.order_by || 0;
      const orderB = b[1][0]?.category?.order_by || 0;
      return orderA - orderB;
    });

      const sortedTemp = Object.fromEntries(tempArray);

      let arr = Object.entries(sortedTemp)
      const sepArr = Object.entries(sortedTemp).map((item, index) => {
        const count = 0
        return {
          ...item,
          catName: item[0],
          description: '',
          catIndex: props.catgoryOrder[index],
          item: item
        }
      })
      // sepArr.sort((a, b) => a.catIndex - b.catIndex);
      setSortCategoryName(sepArr)
      setSelectedSubTabData(arr?.[0]?.[1])
      setSelectedKey(sepArr?.[0]?.catName)
      // setLoading(true)
    }
    // }, 1000);
  }, [props.data])

  const selCat = selectedSubTabData?.[0]?.category
  const categoryDesc = cookies.get("i18next") === "en"
  ? selCat?.description
  : cookies.get("i18next") === "fn"
  ? selCat?.description_fr
  : cookies.get("i18next") === "du"
  ? selCat?.description_nl
  : selCat?.description
  return (
    <div className="w_90per mx-auto overflowYScroll d-flex flex-column justify-content-between height_in_desktop_mobile">
      <div className="position-absolute top-0 end-0 mt-5 me-5 z_index-1">
        <img src={'/assets/imgs/new_center_bubbles.png'} height={70} />
      </div>
      {/* <div className="tab-content scroll_tab_content pt-5 mt-0 h_200px position-relative flexGrowOne" id="myTabContent"> */}
      <div className="tab-content pt-5 mt-0 position-relative flexGrowOne" id="myTabContent">
      <ul
          className={`nav nav-tabs second_tabs icons ${
            props?.data &&
            props?.data?.[selectedKey] &&
            props?.data?.[selectedKey].length - 1 >= 6
              ? " overflow-scroll"
              : null
          }`}
          id="myTab"
          role="tablist"
        >
          {
            props?.errorProducts ? (
              <div
                style={{
                  alignSelf: "center",
                  top: "60%",
                  fontWeight: "bold",
                  textAlign: "center",
                  color: "red",
                  fontSize: 18,
                  position: "absolute",
                }}
              >
                {t("Laundry place not found for the given collection point")}
              </div>
            ) : (
              // props.data && props?.data?.[selectedKey]?.length != undefined && props?.data?.[selectedKey]?.length > 0 ?
              <>

                {sortCategoryName.map((item, index) => {
                  translatedObjen = item.item?.[1]?.[0]?.category?.description;
                  translatedObjfr =
                    item.item?.[1]?.[0]?.category?.description_fr;
                  translatedObjdu =
                    item.item?.[1]?.[0]?.category?.description_nl;
                  return (
                    <div
                      onClick={() => {
                        setSelectedSubTabData(item.item[1]);
                        setSelectedKey(item.item[0]);
                      }}
                    >
                      <li className="nav-item" role="presentation">
                        <a
                          style={{ borderRadius: 10 }}
                          className={`nav-link p-3 h-100 ${
                            selectedKey === item.item?.[0] ? "active" : null
                          }`}
                        >
                          <div className="d-flex flex-row align-items-center mb-1">
                            <img
                              src={
                                Config.apiImage +
                                item.item?.[1]?.[0].category.dark_icon
                              }
                              width={30}
                              className="first h_30px me-2"
                              alt=""
                            />
                            <img
                              src={
                                Config.apiImage +
                                item.item?.[1]?.[0].category.icon
                              }
                              // src="/assets/imgs/bottom.png"
                              width={30}
                              className="second h_30px me-2"
                              alt=""
                            />
                            <h3 className="fs-5 mb-0 third fw-bold">
                              {item.catName}
                            </h3>
                          </div>
                          {/* {item[0] === 'Kilo' && <p className="small mb-0">{item[1]?.[0]?.category.over_category.description}</p>} */}
                          {/* <p className="small mb-0 mw-100">{cookies.get("i18next") === "en" ? translatedObjen : cookies.get("i18next") === "fn" ? translatedObjfr : cookies.get("i18next") === "du" ? translatedObjdu : translatedObjen}</p> */}
                          {/* <p className="small mb-0">Returned wash, dry, iron &amp; fold.</p> */}
                        </a>
                      </li>
                    </div>
                  );
                })}
              </>
            )
            // :
            // <div style={{alignSelf:'center',top:'60%',fontWeight:'bold',textAlign:'center',color:'red',fontSize:18,position:'absolute'}}>{t("Wosh is not available in your area. please select different address.")}</div>
          }

        </ul>
        <p className="theme_text theme_color mt-4">
          {categoryDesc}
        </p>
        {/* {sortCategoryName.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {selectedKey === item.item[0] && (
                <p className="theme_text theme_color mt-4">
                  <p className="small mb-0 mw-100">
                    {cookies.get("i18next") === "en"
                      ? item.item?.[1]?.[0]?.category?.description
                      : cookies.get("i18next") === "fn"
                      ? item.item?.[1]?.[0]?.category?.description_fr
                      : cookies.get("i18next") === "du"
                      ? item.item?.[1]?.[0]?.category?.description_nl
                      : item.item?.[1]?.[0]?.category?.description}
                  </p>
                </p>
              )}
            </React.Fragment>
          );
        })} */}
        <div className="d-flex justify-content-between align-items-center flex_wrap">
          <OnHangersTab data={selectedSubTabData ?? []} />
          {selectedSubTabData && selectedSubTabData?.[0] && selectedSubTabData?.[0]?.category?.name === 'Kilo' &&
            <div className="card_description kilo_tab_shadow pt-2">
              <ul class="nav nav-pills mb-3 justify-content-around" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active color_text" id="whats_included-tab" data-bs-toggle="pill" data-bs-target="#whats_included" type="button" role="tab" aria-controls="whats_included" aria-selected="true">{t("What's included?")}</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link color_text" id="how-to-estimate-tab" data-bs-toggle="pill" data-bs-target="#how-to-estimate" type="button" role="tab" aria-controls="how-to-estimate" aria-selected="false">{t("How to estimate?")}</button>
                </li>
              </ul>
              <div class="tab-content p-4 pt-0 fw-bold" id="pills-tabContent">
                <div class="tab-pane fontInMobile fade show active" id="whats_included" role="tabpanel" aria-labelledby="whats_included-tab">{t("Our service accepts everyday casual and home items such as jeans, t-shirts, sweaters, underwear, sportswear, bed sheets, pillow covers, towels, and more. If we find delicate items like shirts or dresses in your kilo bag, we will handle them with extra care. However, please note that we will charge separately for these items.")}</div>
                <div class="tab-pane fontInMobile fade" id="how-to-estimate" role="tabpanel" aria-labelledby="how-to-estimate-tab">{t("* Unsure about weight? We've got covered! Trust our Knowledgeable experts to determine the weight for you and change the appropriate fee")}</div>
              </div>
            </div>
          }
        </div>
        {/* <OnHangersTab />
        <KiloTab />
        <FoldedTab />
        <IroningTab />
        <KiloTab />
        <FavoriteTab /> */}
      </div>
      {/* <div className="d-flex flex-row position-absolute bottom-0 w_90per mx-auto"> */}
      <div className="d-flex flex-row flexShrinkZero hiddenInMobile_footer">
        <Footer />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  items: state.cart.items,
  totalAmount: state.cart.totalAmount,
  sortedCategories: state.cart.sortedCategories,
  errorProducts: state.cart.error
});

export default connect(mapStateToProps)(TabContent);
